
import {defineComponent} from "vue";
import InfoBox from "@/components/base/InfoBox.vue";

export default defineComponent({
  name: "List",
  components: {
    InfoBox
  },
  setup() {

  },
  methods: {

  }
})
